import { render, useEffect } from '@wordpress/element';
const $ = jQuery;

import './style.scss';
import Style from './Style';

// Sticky Menu
document.addEventListener('DOMContentLoaded', () => {
	const allSticky = document.querySelectorAll('.wp-block-smb-sticky');
	allSticky.forEach(sticky => {
		const { attributes, content } = JSON.parse(sticky.dataset.props);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<StickyMenu attributes={attributes} content={content} />
		</>, sticky);

		sticky?.removeAttribute('data-props')
	});
});

const StickyMenu = ({ attributes, content }) => {
	const { cId, topPosition, minScreenWidth, maxScreenWidth } = attributes;

	useEffect(() => {
		$(`#smbStickyMenu-${cId} .smbStickyMenu`).stickThis({
			top: topPosition,					// top position of sticky element, measured from 'ceiling'
			minscreenwidth: minScreenWidth,		// element will not be sticky when viewport width smaller than this
			maxscreenwidth: maxScreenWidth,		// element will not be sticky when viewport width larger than this 
			zindex: 1,							// z-index value of sticky element
			debugmode: false,					// when true, errors will be logged to console
			pushup: ''							// another (unique) element on the page that will 'push up' the sticky element
		});
	}, []);

	return <div className='smbStickyMenu' dangerouslySetInnerHTML={{ __html: content }}></div>
}
export default StickyMenu;