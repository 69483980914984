const Style = ({ attributes, clientId }) => {
	const { background, padding } = attributes;

	return <style dangerouslySetInnerHTML={{
		__html: `
		#smbStickyMenu-${clientId} .smbStickyMenu{
			${background?.styles || 'background-color: #333;'}
			padding: ${padding?.styles || '10px 20px'};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;